export default function setPageTitle (title) {
  const state = window.store.getState()
  const company = state.orm.companies.first()

  if (typeof title === 'function') {
    title = title()
  }

  document.title = `${title} | ${company && company.name} | Booqable`
}
