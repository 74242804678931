export default function mergeDefaultRequestOptions (options, defaults) {
  if (!options) options = {}

  let include = defaults.include || []

  if (options.include) {
    include.push(options.include)
  }

  include = include.join(',')

  let fields = null

  if (options.fields) {
    fields = Object.assign({}, options.fields || {})

    Object.keys(fields).forEach((field) => {
      const fieldDefaults = defaults.fields && defaults.fields[field]

      if (fieldDefaults) {
        fields[field] = fields[field].concat(fieldDefaults)
        fields[field] = fields[field].join(',')
      }
    })
  }

  const args = {
    include
  }

  if (fields) {
    args.fields = fields
  }

  return args
}
