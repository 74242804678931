import { useNavigate } from 'react-router-dom'

const History = {
  navigate: null,
  push: (page, ...rest) => History.navigate(page, ...rest)
}

const RouteSetter = () => {
  History.navigate = useNavigate()

  return null
}

export {
  History,
  RouteSetter
}
