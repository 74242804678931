import _every from 'lodash/every'

export const FeatureFlags = {
  // Enable address validation for all addresses
  NEW_ADDRESS_VALIDATION: 'new_address_validation',
  // Use the new address fields for location addresses
  NEW_ADDRESS_LOCATION: 'new_address_location',
  // Use the new address fields for order addresses
  NEW_ADDRESS_ORDER: 'new_address_order',
  // Use the new address fields for customer addresses
  NEW_ADDRESS_CUSTOMER: 'new_address_customer',
  // Show the banner to enable address validation and
  // warnings for existing invalid addresses
  NEW_ADDRESS_WARNINGS: 'new_address_warnings',
  // Use the new address fields for company addresses
  NEW_ADDRESS_COMPANY: 'new_address_company',
  // Use the new address fields for company billing addresses
  NEW_ADDRESS_COMPANY_BILLING: 'new_address_company_billing',
  // Enable the delivery options
  DELIVERIES: 'deliveries'
}

/**
 * Checks if feature is enabled.
 */
export const featureEnabled = (...features) => {
  const state = (window.store || window.booqableGetStore?.())?.getState()
  const featuresArray = state.session?.enabledFeatures || state.client?.enabledFeatures

  return _every(features, (feature) => {
    return (featuresArray || []).includes(feature)
  })
}

/**
 * Checks if feature is disabled.
 */
export const featureDisabled = (...features) => !featureEnabled(...features)

/**
 * Backoffice only
 * Checks if feature is part of subscription.
 */
export const featureAllowed = (...features) => {
  const state = window.store?.getState()

  return _every(features, (feature) => {
    return (state.session.features || []).includes(feature)
  })
}
